import request from '@modules/api';
import dayjs from 'dayjs';

let timerIdByDebounce = 0;

export const translateTypeUser = (type) => {
  switch (type) {
  case 'user':
    return 'Пользователь';
  case 'admin':
    return 'Администратор';
  case 'auditor':
    return 'Ревизор';
  default:
    return 'Банковский отдел';
  }
};

export const asyncDebounce = (url, cb, delay = 800) => {
  if (timerIdByDebounce) {
    clearTimeout(timerIdByDebounce);
  }
  timerIdByDebounce = setTimeout(async () => {
    const result = await request(url);
    cb(result);
  }, delay);
};

export const formatDate = (isoDate) => {
  const date = dayjs(isoDate).format('DD.MM.YYYY HH:mm');
  return date;
};

export const numberFormat = (currency = 'RUB', n) =>
  new Intl.NumberFormat(getLocal(currency), {
    style: 'currency',
    currency
  }).format(n);

const getLocal = (type) => {
  switch (type) {
  case 'RUB':
    return 'ru-RU';
  case 'BYN':
    return 'be-BY';
  case 'KZT':
    return 'kk-KZ';
  default:
    return 'uz-UZ';
  }
};

export const parseCurrencyString = (currencyString) => {
  const sanitizedString = currencyString.replace(/[^\d,.-]/g, '').replace(',', '.');
  const parsedNumber = parseFloat(sanitizedString);

  return isNaN(parsedNumber) ? null : parsedNumber;
};
