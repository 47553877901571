import React, {useEffect, useState} from 'react';
import {Bill} from '@components/Bill/Bill';
import api from '@modules/api';
import {Button, Result} from 'antd';
import {Filters} from '@components/BillFilters/Filters';
import {SearchOutlined} from '@ant-design/icons';
import './styles/style.css';
import style from './styles/style.module.scss';

export const BillsHistory = ({toggleLoading}) => {
  const [bills, setBills] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [firms, setFirms] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [filters, setFilters] = useState({
    firm: undefined,
    org: undefined,
    exp: undefined,
    author: undefined,
    create: undefined,
    payDate: undefined,
    type: undefined,
    id: '',
    paid: false,
    confirmation: false,
    canceled: false
  });
  const updateData = async (filters, offset = 0) => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });
    await getFiltredBills(newParams, offset);
  };

  const mainContent = document.getElementsByTagName('main')[0];

  const toTop = () => mainContent.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  const setFilterByTitle = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }

    const newFilters = {...filters, ...updatedFilters};
    setFilters(newFilters);
  };

  const setOrgs = (items) => {
    if (!items.length) {
      setFilters({
        ...filters,
        org: undefined,
        type: undefined
      });
      return;
    }
    const newFilters = {
      ...filters,
      org: items
    };
    setFilters(newFilters);
  };

  const idSearch = async () => {
    const newParams = new URLSearchParams();
    newParams.set('id', filters.id);
    toTop();
    await getFiltredBills(newParams);
  };

  const applyFilters = async () => {
    toTop();
    await updateData(filters);
  };

  const fetchBills = async (params, offset = 0) => {
    const response = await api(`bill/history?offset=${offset}${params.size ? `&${params}` : ''}`);
    if (response.status === 200) {
      setBills([
        ...(offset === 0 ? [] : bills),
        ...response.data
      ]);
      if (!response.data.length) {
        setEmpty(true);
      } else {
        setEmpty(false);
      }
    }
  };

  const getFiltredBills = async (params, offset) => {
    toggleLoading();
    await fetchBills(params, offset);
    toggleLoading();
  };

  const fetchFilters = async () => {
    const response = await api(`bill/filters`);
    if (response) {
      if (response?.status) {
        const [firmsData, sideFirmsData, statesData, expensesData] = response.data;
        if (firmsData.status) {
          setFirms(
            firmsData.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          );
        }
        if (expensesData.status) {
          setExpenses(expensesData.data);
        }
        const organizationData = [
          ...sideFirmsData.data?.map((val) => ({
            ...val,
            typeOrganization: 'organization'
          })),
          ...statesData.data?.map((val) => ({
            ...val,
            name: val.title,
            typeOrganization: 'state'
          }))
        ].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setOrganizations(organizationData);
      }
    }
  };

  const getData = async () => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });

    await fetchBills(newParams);
  };

  const fetchInitialData = async () => {
    await Promise.all([
      fetchFilters(),
      getData()
    ]);
  };

  useEffect(() => {
    (async () => {
      await fetchInitialData();
      toggleLoading();
    })();
  }, []);

  const resetFilters = async (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }
    const newFilters = {
      ...filters,
      firm: undefined,
      org: undefined,
      type: undefined,
      exp: undefined,
      author: undefined,
      create: undefined,
      payDate: undefined,
      id: '',
      paid: false,
      confirmation: false,
      canceled: false,
      awaitingPayment: false,
      ...updatedFilters
    };
    setFilters(newFilters);

    toTop();

    await updateData(newFilters);
  };

  const excelExportHandler = async () => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });
    await api(`bill/history-excel?${newParams.size ? `&${newParams}` : ''}`, {}, true).then((data) => data.data.blob()).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'История счетов.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <Button onClick={excelExportHandler} type="primary" style={{marginBottom: '5px'}}>
        Экспорт в Excel
      </Button>
      <div className={style.Container}>
        <div className={style.Cards}>
          {bills.map((el) => (
            <Bill bill={el} forPayment={false} showAuthor={true} toggleLoading={toggleLoading}/>
          ))}
          {bills.length && bills.length > 9 && !(bills.length % 10) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Button type={'primary'} onClick={() => updateData(filters, bills.length)}>
                Загрузить ещё
              </Button>
            </div>
          ) : null}
          {empty &&
            <Result className="no-bills" icon={<SearchOutlined/>} status="info" title="Согласований не найдено"/>}
        </div>
        <div className={style.Filters}>
          <Filters
            mode={'history'}
            applyFilters={applyFilters}
            passed={true}
            filters={filters}
            firms={firms}
            orgs={organizations}
            expenses={expenses}
            authors={authors}
            setAuthorOptions={setAuthors}
            setOrgs={setOrgs}
            idSearch={idSearch}
            resetFilters={resetFilters}
            setFilterByTitle={setFilterByTitle}
          />
        </div>
      </div>
    </>
  );
};
