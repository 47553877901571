import React, {
  useEffect, useState
} from 'react';
import {
  Card, Typography, Space, DatePicker, Button, Select, Checkbox, Drawer, Modal,
  Input
} from 'antd';
import {Calendar, ConfigProvider} from 'antd-mobile';
import ru from 'antd-mobile/es/locales/ru-RU';
import './styles/filters.css';
import style from './styles/filters.module.scss';
import {
  SearchOutlined, PlusOutlined, FilterOutlined
} from '@ant-design/icons';
import {useDebaunce} from '@modules/useDebounce';
import api from '@modules/api';
import dayjs from 'dayjs';

export function Filters({
  filters,
  firms,
  orgs,
  expenses,
  authors,
  setFilterByTitle,
  idSearch,
  setAuthorOptions,
  setOrgs,
  resetFilters,
  applyFilters,
  openModal,
  isBanking = false,
  mode
}) {
  const [
    debaunce,
    clearDebaunce
  ] = useDebaunce();
  const [
    size,
    setSize
  ] = useState(window.innerWidth <= 1160 ? 'mobile' : 'desktop');
  const [
    open,
    setOpen
  ] = useState(false);
  const [
    mobilePickerOpen,
    setMobilePickerOpen
  ] = useState({
    create: false,
    pay: false
  });

  const getAuthors = async (value) => {
    const authorsData = await api(`user/search/${value}`);
    if (authorsData.status) {
      setAuthorOptions(authorsData.data);
    }
  };

  const handleSearch = (e) => {
    if (e.length >= 3) {
      debaunce(() => {
        getAuthors(e);
      });
    }
  };

  const handleDatePicker = (e, key) => {
    const start = e[0] ? e[0].format('YYYY-MM-DD') : undefined;
    const end = e[1] ? e[1].format('YYYY-MM-DD') : undefined;

    if (start && end) {
      setFilterByTitle(key, [
        start,
        end
      ]);
    } else {
      setFilterByTitle(key, undefined);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDatePickerMobile = (e, key) => {
    if (e == null) {
      return setFilterByTitle(key, undefined);
    }
    const start = e[0] ? dayjs(e[0]).format('YYYY-MM-DD') : undefined;
    const end = e[1] ? dayjs(e[1]).format('YYYY-MM-DD') : undefined;

    if (start && end) {
      setFilterByTitle(key, [
        start,
        end
      ]);
    } else {
      setFilterByTitle(key, undefined);
    }
    return null;
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1160) {
        setSize('mobile');
      } else {
        setSize('desktop');
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
      clearDebaunce();
    };
  }, []);

  const checkMode = () => mode === 'history' || (mode === 'payment' && filters.mode === 'passed');

  const statusComponent = () => <>
    <Typography style={{
      fontWeight: 600,
      fontSize: '16px'
    }}>Статус счёта</Typography>
    <Space direction={'vertical'}>
      <Checkbox checked={filters.confirmation}
                onChange={(e) => setFilterByTitle('confirmation', e.target.checked)}>
        На рассмотрении
      </Checkbox>
      <Checkbox checked={filters.awaitingPayment}
                onChange={(e) => setFilterByTitle('awaitingPayment', e.target.checked)}>
        Ожидает оплаты
      </Checkbox>
      <Checkbox checked={filters.paid} onChange={(e) => setFilterByTitle('paid', e.target.checked)}>
        Оплачен
      </Checkbox>
      <Checkbox checked={filters.canceled}
                onChange={(e) => setFilterByTitle('canceled', e.target.checked)}>
        Отклонён
      </Checkbox>
    </Space>
  </>;

  return (
    <div style={{
      position: 'sticky',
      top: 0
    }}>
      {size === 'mobile' ? (
        <>
          {mode === 'my' ? (
            <Button type="primary" size="large" style={{
              marginBottom: 12,
              width: '100%'
            }} onClick={openModal}>
              <PlusOutlined/>
              {' '}
              Создать согласование
            </Button>
          ) : (
            ''
          )}
          <div style={{
            display: 'grid',
            gridTemplateColumns: '1fr 40px',
            gap: '8px'
          }}
          >
            <Space.Compact>
              <Input
                // size="large"
                // min="1"
                style={{width: '100%'}}
                placeholder="По номеру и ключевым словам"
                defaultValue={filters.id}
                value={filters.id}
                onChange={(e) => setFilterByTitle('id', e.target.value, true)}
              />
              <Button size="large" type="primary" onClick={idSearch} icon={<SearchOutlined/>}/>
            </Space.Compact>
            <Button size="large" onClick={() => setOpen(true)} icon={<FilterOutlined/>}/>
          </div>
          <Drawer
            destroyOnClose
            styles={{
              body: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              },
              header: {padding: '31px 24px'}
            }}
            width="100%"
            open={open}
            onClose={() => setOpen(false)}
          >
            <div>
              <Typography className={style.Title}>Фильтры</Typography>
              <Space direction="vertical">
                <Space.Compact style={{width: 300}}>
                  <Input
                    style={{width: '100%'}}
                    placeholder="По номеру и ключевым словам"
                    defaultValue={filters.id}
                    value={filters.id}
                    onChange={(e) => setFilterByTitle('id', e.target.value, true)}
                  />
                  <Button type="primary" onClick={idSearch} icon={<SearchOutlined/>}/>
                </Space.Compact>
                <Space>
                  <Select
                    style={{
                      minWidth: '300px',
                      maxWidth: '300px'
                    }}
                    onChange={(e) => setFilterByTitle('exp', e.length ? e : undefined)}
                    optionFilterProp="label"
                    showSearch
                    allowClear
                    mode="multiple"
                    placeholder="Статья расходов"
                    options={expenses?.map(({
                      id,
                      name,
                      number
                    }) => ({
                      value: id,
                      label: `${number} ${name}`
                    }))}
                    value={filters.exp?.length ? Array.from(filters.exp, Number) : []}
                  />
                </Space>
                <Space>
                  <Select
                    style={{minWidth: '300px'}}
                    onChange={(e) => setFilterByTitle('firm', e)}
                    allowClear
                    placeholder="Фирма"
                    options={firms?.map(({
                      id,
                      name
                    }) => ({
                      value: id,
                      label: name
                    }))}
                    value={firms.length && filters.firm}
                  />
                </Space>
                <Space>
                  <Select
                    style={{
                      minWidth: '300px',
                      maxWidth: '300px'
                    }}
                    optionFilterProp="label"
                    showSearch
                    mode="multiple"
                    allowClear
                    value={filters.org?.length ? filters.org : []}
                    placeholder="Филиал"
                    onChange={(e) => setOrgs(e)}
                    options={orgs?.map((org) => ({
                      value: `${org.id}/${org.typeOrganization}`,
                      label: org?.name || org?.title
                    }))}
                  />
                </Space>
                {checkMode() ? (
                  <Space>
                    <Select
                      style={{
                        minWidth: '300px',
                        maxWidth: '300px'
                      }}
                      filterOption={false}
                      allowClear
                      showSearch
                      placeholder="Автор согласования"
                      value={filters.author}
                      options={authors?.map((item) => ({
                        value: item.id,
                        label: `${item.f} ${item.i} ${item.o} ${item.email}`
                      }))}
                      onSearch={handleSearch}
                      onChange={(e) => setFilterByTitle('author', e, true)}
                    />
                  </Space>
                ) : (
                  ''
                )}

                <Space>
                  <DatePicker.RangePicker
                    onFocus={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      setMobilePickerOpen((prev) => ({
                        ...prev,
                        create: true
                      }));
                      e.target.blur();
                    }}
                    className={style.Datepicker}
                    format="DD.MM.YYYY"
                    onCalendarChange={(e) => handleDatePicker(e, 'create')}
                    value={filters.create && filters.create?.map((date) => dayjs(date))}
                    placeholder={[
                      'Дата создания',
                      ''
                    ]}
                    open={false}
                    style={{
                      minWidth: '300px',
                      maxWidth: '300px'
                    }}
                  />
                  <Modal
                    onCancel={() => setMobilePickerOpen((prev) => ({
                      ...prev,
                      create: false
                    }))}
                    onClose={() => setMobilePickerOpen((prev) => ({
                      ...prev,
                      create: false
                    }))}
                    onOk={() => setMobilePickerOpen((prev) => ({
                      ...prev,
                      create: false
                    }))}
                    styles={{
                      wrapper: {padding: 0},
                      content: {padding: '7px'}
                    }}
                    destroyOnClose
                    open={mobilePickerOpen.create}
                  >
                    <ConfigProvider locale={ru}>
                      <Calendar
                        style={{marginTop: '50px'}}
                        allowClear={true}
                        selectionMode="range"
                        onChange={(e) => handleDatePickerMobile(e, 'create')}
                        defaultValue={filters.create && filters.create?.map((date) => dayjs(date))}
                      />
                    </ConfigProvider>
                  </Modal>
                </Space>
                {checkMode() ? (
                  <Space>
                    <DatePicker.RangePicker
                      onFocus={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setMobilePickerOpen((prev) => ({
                          ...prev,
                          pay: true
                        }));
                        e.target.blur();
                      }}
                      format="DD.MM.YYYY"
                      onCalendarChange={(e) => handleDatePicker(e, 'payDate')}
                      value={filters.payDate && filters.payDate?.map((date) => dayjs(date))}
                      placeholder={[
                        'Дата оплаты',
                        ''
                      ]}
                      open={false}
                      style={{
                        minWidth: '300px',
                        maxWidth: '300px'
                      }}
                    />
                    <Modal
                      onCancel={() => setMobilePickerOpen((prev) => ({
                        ...prev,
                        pay: false
                      }))}
                      onClose={() => setMobilePickerOpen((prev) => ({
                        ...prev,
                        pay: false
                      }))}
                      onOk={() => setMobilePickerOpen((prev) => ({
                        ...prev,
                        pay: false
                      }))}
                      styles={{
                        wrapper: {padding: 0},
                        content: {padding: '7px'}
                      }}
                      destroyOnClose
                      open={mobilePickerOpen.pay}
                    >
                      <ConfigProvider locale={ru}>
                        <Calendar
                          style={{marginTop: '50px'}}
                          allowClear={true}
                          selectionMode="range"
                          onChange={(e) => handleDatePickerMobile(e, 'payDate')}
                          defaultValue={filters.payDate && filters.payDate?.map((date) => dayjs(date))}
                        />
                      </ConfigProvider>
                    </Modal>
                  </Space>
                ) : (
                  ''
                )}

                {mode === 'payment' && filters.mode === 'forPass' && isBanking ? (
                  <Space>
                    <DatePicker
                      onFocus={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setMobilePickerOpen((prev) => ({
                          ...prev,
                          pay: true
                        }));
                        e.target.blur();
                      }}
                      format="DD.MM.YYYY"
                      onCalendarChange={(e) => setFilterByTitle('nearestDate', e?.format('YYYY-MM-DD'))}
                      value={filters.nearestDate && dayjs(filters.nearestDate)}
                      placeholder={
                        'Дата поступления'
                      }
                      open={false}
                      style={{
                        minWidth: '300px',
                        maxWidth: '300px'
                      }}
                    />
                    <Modal
                      onCancel={() => setMobilePickerOpen((prev) => ({
                        ...prev,
                        pay: false
                      }))}
                      onClose={() => setMobilePickerOpen((prev) => ({
                        ...prev,
                        pay: false
                      }))}
                      onOk={() => setMobilePickerOpen((prev) => ({
                        ...prev,
                        pay: false
                      }))}
                      styles={{
                        wrapper: {padding: 0},
                        content: {padding: '7px'}
                      }}
                      destroyOnClose
                      open={mobilePickerOpen.pay}
                    >
                      <ConfigProvider locale={ru}>
                        <Calendar
                          style={{marginTop: '50px'}}
                          allowClear={true}
                          selectionMode="single"
                          onChange={(e) => setFilterByTitle('nearestDate', e ? dayjs(e).format('YYYY-MM-DD') : undefined)}
                          defaultValue={filters.nearestDate && dayjs(filters.nearestDate)}
                        />
                      </ConfigProvider>
                    </Modal>
                  </Space>
                ) : (
                  ''
                )}

                {filters.mode !== 'forPass' && statusComponent()}
                <div style={{
                  display: 'flex',
                  gap: '12px',
                  marginTop: '10px'
                }}>
                  <Button
                    style={{flex: '1 0 auto'}}
                    onClick={() => {
                      resetFilters();
                      setOpen(false);
                    }}
                  >
                    Сбросить
                  </Button>
                  <Button
                    style={{flex: '1 0 auto'}}
                    onClick={() => {
                      applyFilters();
                      setOpen(false);
                    }}
                    type="primary"
                  >
                    Применить
                  </Button>
                </div>
              </Space>
            </div>
          </Drawer>
        </>
      ) : (
        <>
          {mode === 'my' ? (
            <Button type={'primary'} size="large" style={{
              marginBottom: 5,
              width: '100%'
            }} onClick={openModal}>
              <PlusOutlined/> Создать согласование
            </Button>
          ) : (
            ''
          )}
          <Card styles={{body: {padding: '12px'}}}>
            <Typography className={style.Title}>Фильтры</Typography>
            <Space direction="vertical" wrap>
              <Space.Compact style={{width: 300}}>
                <Input
                  style={{width: '100%'}}
                  placeholder="По номеру и ключевым словам"
                  defaultValue={filters.id}
                  value={filters.id}
                  onChange={(e) => setFilterByTitle('id', e.target.value, true)}
                />
                <Button type="primary" onClick={idSearch} icon={<SearchOutlined/>}/>
              </Space.Compact>
              <Space>
                <Select
                  style={{
                    minWidth: '300px',
                    maxWidth: '300px'
                  }}
                  onChange={(e) => setFilterByTitle('exp', e.length ? e : undefined)}
                  optionFilterProp="label"
                  showSearch
                  allowClear
                  mode="multiple"
                  placeholder={'Статья расходов'}
                  options={expenses?.map(({
                    id,
                    name,
                    number
                  }) => ({
                    value: id,
                    label: `${number} ${name}`
                  }))}
                  value={filters.exp?.length ? Array.from(filters.exp, Number) : []}
                />
              </Space>
              <Space>
                <Select
                  style={{minWidth: '300px'}}
                  onChange={(e) => setFilterByTitle('firm', e)}
                  allowClear
                  placeholder={'Фирма'}
                  options={firms?.map(({
                    id,
                    name
                  }) => ({
                    value: id,
                    label: name
                  }))}
                  value={firms.length && filters.firm}
                />
              </Space>
              <Space>
                <Select
                  id="filial"
                  className="filial"
                  style={{
                    minWidth: '300px',
                    maxWidth: '300px'
                  }}
                  optionFilterProp="label"
                  showSearch
                  mode="multiple"
                  placement={'topRight'}
                  allowClear
                  value={filters.org?.length ? filters.org : []}
                  placeholder={'Филиал'}
                  onChange={(e) => setOrgs(e)}
                  options={orgs?.map((org) => ({
                    value: `${org.id}/${org.typeOrganization}`,
                    label: org?.name || org?.title
                  }))}
                />
              </Space>
              {checkMode() && <Space>
                <Select
                  style={{
                    minWidth: '300px',
                    maxWidth: '300px'
                  }}
                  filterOption={false}
                  allowClear
                  showSearch
                  placeholder="Автор согласования"
                  value={filters.author}
                  options={authors?.map((item) => {
                    return {
                      value: item.id,
                      label: `${item.f} ${item.i} ${item.o} ${item.email}`
                    };
                  })}
                  onSearch={handleSearch}
                  onChange={(e) => setFilterByTitle('author', e, true)}
                />
              </Space>}

              <Space>
                <DatePicker.RangePicker
                  format="DD.MM.YYYY"
                  onCalendarChange={(e) => handleDatePicker(e, 'create')}
                  value={filters.create && filters.create?.map((date) => dayjs(date))}
                  placeholder={[
                    'Дата создания',
                    ''
                  ]}
                  style={{
                    minWidth: '300px',
                    maxWidth: '300px'
                  }}
                />
              </Space>
              {checkMode() && <Space>
                <DatePicker.RangePicker
                  format="DD.MM.YYYY"
                  onCalendarChange={(e) => handleDatePicker(e, 'payDate')}
                  value={filters.payDate && filters.payDate?.map((date) => dayjs(date))}
                  placeholder={[
                    'Дата оплаты',
                    ''
                  ]}
                  style={{
                    minWidth: '300px',
                    maxWidth: '300px'
                  }}
                />
              </Space>}

              {mode === 'payment' && filters.mode === 'forPass' && isBanking ? (
                <Space>
                  <DatePicker
                    format="DD.MM.YYYY"
                    onCalendarChange={(e) => setFilterByTitle('nearestDate', e?.format('YYYY-MM-DD'))}
                    value={filters.nearestDate && dayjs(filters.nearestDate)}
                    placeholder={
                      'Дата поступления'
                    }
                    style={{
                      minWidth: '300px',
                      maxWidth: '300px'
                    }}
                  />
                </Space>
              ) : (
                ''
              )}

              {filters.mode !== 'forPass' && statusComponent()}
              <div style={{
                display: 'flex',
                gap: '12px',
                marginTop: '10px'
              }}>
                <Button style={{flex: '1 0 auto'}} onClick={resetFilters}>
                  Сбросить
                </Button>
                <Button style={{flex: '1 0 auto'}} onClick={applyFilters} type="primary">
                  Применить
                </Button>
              </div>
            </Space>
          </Card>
        </>
      )}
    </div>
  );
}
