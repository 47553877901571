import React, {useContext, useEffect, useState} from 'react';
import {Bill} from '@components/Bill/Bill';
import api from '@modules/api';
import {
  Result, Space, Button, Typography
} from 'antd';
import {Filters} from '@components/BillFilters/Filters';
import {SearchOutlined} from '@ant-design/icons';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import {isMobile} from 'react-device-detect';
import './styles/style.css';
import style from './styles/style.module.scss';
import UserContext from '@context/UserContext';
import dayjs from 'dayjs';

const isBetween = require('dayjs/plugin/isBetween');
dayjs.extend(isBetween);

export function BillsForPayment({toggleLoading}) {
  const userSelf = useContext(UserContext);
  const {isBankingDepartment} = userSelf;
  const firmLocal = isBankingDepartment ? localStorage.getItem('firmLocal') : undefined;
  const orgLocal = isBankingDepartment ? localStorage.getItem('orgLocal') : undefined;

  const [bills, setBills] = useState([]);
  const [tempBills, setTempBills] = useState([]);
  const [selected, setSelected] = useState(new Map());
  const [organizations, setOrganizations] = useState([]);
  const [firms, setFirms] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [downloadPending, setDownloadPending] = useState(false);
  const [emptyText, setEmptyText] = useState('Согласований не найдено');
  const [filters, setFilters] = useState({
    mode: 'forPass',
    firm: firmLocal ? Number(firmLocal) : undefined,
    org: orgLocal ? orgLocal.split(',') : undefined,
    exp: undefined
  });

  const [copyFilters, setCopyFilters] = useState({
    firm: firmLocal ? Number(firmLocal) : undefined,
    org: orgLocal ? orgLocal.split(',') : undefined
  });

  const checkLocalFilters = () => {
    return firmLocal || orgLocal;
  };

  const getBillsToLocalFilter = (data = []) => {
    const keysFilter = [
      'nearestDate',
      'create',
      'exp',
      'id'
    ];
    let localData = [];
    if (keysFilter.some(key => filters[key])) {
      keysFilter.forEach(key => {
        const tempData = localData.length > 0 ? localData : data;
        if (key === 'nearestDate' && filters[key]) {
          localData = tempData.filter(item => {
            const last = item.billConfirmations[item.billConfirmations.length - 1];
            return dayjs(last.eventDate).format('YYYY-MM-DD') === filters[key];
          });
        }
        if (key === 'create' && filters[key]) {
          localData = tempData.filter(item => {
            const {createdAt} = item;
            const dateStart = dayjs(filters[key][0]).subtract(1, 'day');
            const dateEnd = dayjs(filters[key][1]).add(1, 'day');
            return dayjs(createdAt).isBetween(dateStart, dateEnd, 'day');
          });
        }
        if (key === 'exp' && filters[key]) {
          localData = tempData.filter(item => {
            const {expensesId} = item;
            return filters[key].some(item => item === expensesId);
          });
        }
        if (key === 'id' && filters[key]) {
          localData = tempData.filter(item => {
            const {
              author,
              id,
              comment,
              howToPay
            } = item;
            return Number(filters[key]) === id || filterByAuthor(author, filters[key]) || filterByString(comment, filters[key]) || filterByString(howToPay, filters[key]);
          });
        }
      });
      return localData;
    } else {
      return data;
    }
  };

  const filterByString = (text, keyString) => {
    return text ? text.toLowerCase().includes(keyString.toLowerCase()) : false;
  };

  const filterByAuthor = (author, keyString) => {
    const keys = Object.keys(author);
    return keys.some(key => {
      if (author[key]) {
        return author[key].toLowerCase().includes(keyString.toLowerCase());
      } else {
        return false;
      }
    });
  };

  const updateData = async (filtersLocal) => {
    const newParams = new URLSearchParams();
    Object.keys(filtersLocal).forEach((key) => {
      if (filtersLocal[key] && filtersLocal[key] !== 0) {
        newParams.set(key, filtersLocal[key]);
      }
    });
    await getFiltredBills(newParams);
    setSelected(new Map());
  };

  const setFilterByTitle = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }

    const newFilters = {...filters, ...updatedFilters};
    setFilters(newFilters);
  };

  const setOrgs = (items) => {
    if (!items.length) {
      setFilters({
        ...filters,
        org: undefined,
        type: undefined
      });
      return;
    }
    const newFilters = {
      ...filters,
      org: items
    };
    setFilters(newFilters);
  };

  const checkNewFilter = () => {
    if (isBankingDepartment) {
      return (copyFilters.firm !== filters.firm) || (filters.org?.toString() !== copyFilters.org?.toString());
    } else {
      return true;
    }
  };

  const applyFilters = async () => {
    if (isBankingDepartment) {
      if (filters.firm) {
        localStorage.setItem('firmLocal', filters.firm);
      }
      if (filters.org) {
        localStorage.setItem('orgLocal', filters.org);
      }
      if (!filters.firm) {
        localStorage.removeItem('firmLocal');
      }
      if (!filters.org) {
        localStorage.removeItem('orgLocal');
      }
      if (!filters.firm && !filters.org) {
        setFilters({
          ...filters,
          firm: undefined,
          org: undefined
        });
        setTempBills([]);
        setBills([]);
        setEmptyText('Для поиска выберите филиал или фирму');
        setEmpty(true);
      }
    }
    if (checkNewFilter()) {
      await updateData(filters);
      setCopyFilters({
        firm: filters.firm,
        org: filters.org
      });
    } else {
      setBills(getBillsToLocalFilter(tempBills));
    }
  };

  const handleSelectBill = (id) => {
    if (selected.has(id)) {
      setSelected((prev) => {
        const newObj = new Map(prev);
        newObj.delete(id);
        return newObj;
      });
    } else {
      setSelected((prev) => {
        const newObj = new Map(prev);
        newObj.set(id, true);
        return newObj;
      });
    }
  };

  const fetchBills = async (params) => {
    const response = await api(`bill/payment?${params}`);
    if (response.status === 200) {
      setBills(response.data);
      setTempBills(response.data);
      if (!response.data.length) {
        setEmpty(true);
        setEmptyText('Согласований не найдено');
      } else {
        setEmpty(false);
      }
    }
  };

  const getFiltredBills = async (params) => {
    toggleLoading();
    await fetchBills(params);
    toggleLoading();
  };

  const downloadBills = async () => {
    setDownloadPending(true);
    await api(
      'bill/download',
      {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: [...selected.keys()]
      },
      true
    )
      .then((response) => response.data.json())
      .then((data) => {
        data.forEach((value) => {
          const blob = new Blob([Uint8Array.from(atob(value.content), (c) => c.charCodeAt(0))], {type: 'application/octet-stream'});
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = value.name;
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        });
      });
    setDownloadPending(false);
  };

  const fetchFilters = async () => {
    const response = await api('bill/filters');
    if (response) {
      if (response?.status) {
        const [
          firmsData,
          sideFirmsData,
          statesData,
          expensesData
        ] = response.data;
        if (firmsData.status) {
          setFirms(
            firmsData.data.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
          );
        }
        if (expensesData.status) {
          setExpenses(expensesData.data);
        }
        const organizationData = [
          ...sideFirmsData.data?.map((val) => ({
            ...val,
            typeOrganization: 'organization'
          })),
          ...statesData.data?.map((val) => ({
            ...val,
            name: val.title,
            typeOrganization: 'state'
          }))
        ].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        setOrganizations(organizationData);
      }
    }
  };

  const getData = async () => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });

    await fetchBills(newParams);
  };

  const fetchInitialData = async () => {
    await Promise.all([
      fetchFilters(),
      getData()
    ]);
  };

  useEffect(() => {
    (async () => {
      if (isBankingDepartment) {
        if (checkLocalFilters()) {
          await fetchInitialData();
        } else {
          setEmpty(true);
          setEmptyText('Для поиска выберите филиал или фирму');
          await fetchFilters();
        }
      } else {
        await fetchInitialData();
      }
      toggleLoading();
    })();
  }, []);

  useEffect(() => {
    setBills(getBillsToLocalFilter(tempBills));
  }, [tempBills]);

  const editRequestCallback = (id) => {
    const index = bills.findIndex((val) => val.id === id);
    setBills([
      ...bills.slice(0, index),
      ...bills.slice(index + 1)
    ]);
  };

  const removeBill = async () => {
    await updateData(filters);
  };

  const resetFilters = async () => {
    const newFilters = {
      mode: 'forPass',
      firm: undefined,
      org: undefined,
      exp: undefined
    };
    setFilters(newFilters);
    setCopyFilters(newFilters);
    if (isBankingDepartment) {
      localStorage.removeItem('firmLocal');
      localStorage.removeItem('orgLocal');
      setBills([]);
      setTempBills([]);
      setEmptyText('Для поиска выберите филиал или фирму');
      setEmpty(true);
    } else {
      await updateData(newFilters);
    }
  };

  return (
    <>
      <Space style={{marginBottom: '15px'}} direction="horizontal">
        {selected.size
          ? (
            <Space>
              <Button
                style={{padding: 10}}
                danger
                onClick={() => {
                  setSelected(new Map());
                }}
              >
                Отменить выбор
              </Button>

            </Space>
          )
          : ''}
        <Space>
          <Button
            style={{padding: 10}}
            onClick={() => {
              const newObj = new Map();
              bills.forEach((val) => {
                newObj.set(val.id, true);
              });
              setSelected(newObj);
            }}
          >
            Выбрать все
          </Button>
        </Space>
        <Space>
          <Button style={{padding: 10}} disabled={!selected.size} loading={downloadPending} type="primary"
                  onClick={downloadBills}>
            Скачать
          </Button>
        </Space>
        {selected.size && !isMobile ? (
          <Space>
            <Typography style={{fontWeight: 500}}>
              Выбрано счетов:
              {' '}
              {selected.size}
            </Typography>
          </Space>
        ) : ''}

      </Space>

      <div className={style.Container}>
        <div className={style.Cards}>
          <TransitionGroup>
            {bills.map((el) => (
              <CSSTransition
                key={`${el.id}_${el.billConfirmations.findLast((val) => val.status === 'new')?.user.id}`}
                timeout={500}
                classNames="item"
              >
                <Bill
                  selectMode
                  selected={selected.has(el.id)}
                  onSelect={handleSelectBill}
                  editRequestCallback={editRequestCallback}
                  bill={el}
                  forPayment
                  showAuthor
                  toggleLoading={toggleLoading}
                  removeBill={removeBill}
                />
              </CSSTransition>
            ))}
          </TransitionGroup>
          {empty &&
            <Result className="no-bills" icon={<SearchOutlined/>} status="info" title={emptyText}/>}
        </div>
        <div className={style.Filters}>
          <Filters
            mode="payment"
            isBanking={isBankingDepartment}
            applyFilters={applyFilters}
            passed={false}
            filters={filters}
            firms={firms}
            orgs={organizations}
            expenses={expenses}
            setOrgs={setOrgs}
            idSearch={applyFilters}
            resetFilters={resetFilters}
            setFilterByTitle={setFilterByTitle}
          />
        </div>
      </div>
    </>
  );
}
