/* eslint-disable react/jsx-filename-extension */
import React, {useContext, useEffect, useState, useRef} from 'react';
import {Bill} from '@components/Bill/Bill';
import {
  Space, Button, Modal, Select, Input, InputNumber, Upload, message, Result, Typography
} from 'antd';
import {Filters} from '@components/BillFilters/Filters';
import {UploadOutlined, SearchOutlined} from '@ant-design/icons';
import api from '@modules/api';
import './index.css';
import style from './style.module.scss';
import UserContext from '@context/UserContext';

function MyBill({toggleLoading}) {
  const [
    isOpenModal,
    setIsOpenModal
  ] = useState(false);
  const [
    isCreateBill,
    setIsCreateBill
  ] = useState(false);
  const [
    modalMode,
    setModalMode
  ] = useState('create');
  const [
    empty,
    setEmpty
  ] = useState(false);
  const [
    expenses,
    setExpenses
  ] = useState([]);
  const [
    firms,
    setFirms
  ] = useState([]);
  const [
    organizations,
    setOrganizations
  ] = useState([]);
  const [
    bills,
    setBills
  ] = useState([]);
  const [
    files,
    setFiles
  ] = useState([]);
  const [bill, setBill] = useState({
    files: [],
    billSum: 0.00
  });
  const [
    filters,
    setFilters
  ] = useState({
    firm: undefined,
    org: undefined,
    exp: undefined,
    create: undefined,
    type: undefined,
    id: '',
    paid: false,
    confirmation: false,
    canceled: false
  });

  const userSelf = useContext(UserContext);

  const mainContent = document.getElementsByTagName('main')[0];

  const toTop = () => mainContent.scrollTo({
    top: 0,
    behavior: 'smooth'
  });

  const fetchInitialData = async () => {
    await Promise.all([
      fetchDataFromBill(),
      getData()
    ]);
  };

  const inputRef = useRef(null);

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  };

  useEffect(() => {
    (async () => {
      await fetchInitialData();
      toggleLoading();
    })();
  }, []);

  const updateData = async (filtersLocal, offset = 0) => {
    const newParams = new URLSearchParams();
    Object.keys(filtersLocal).forEach((key) => {
      if (filtersLocal[key] && filtersLocal[key] !== 0) {
        newParams.set(key, filtersLocal[key]);
      }
    });
    await getFiltredBills(newParams, offset);
  };

  const setFilterByTitle = (title, data) => {
    const updatedFilters = {};

    if (Array.isArray(title) && Array.isArray(data)) {
      title.forEach((value, index) => {
        updatedFilters[value] = data[index];
      });
    } else {
      updatedFilters[title] = data;
    }

    const newFilters = {...filters, ...updatedFilters};
    setFilters(newFilters);
  };

  const setOrgs = (items) => {
    if (!items.length) {
      setFilters({
        ...filters,
        org: undefined,
        type: undefined
      });
      return;
    }
    const newFilters = {
      ...filters,
      org: items
    };
    setFilters(newFilters);
  };

  const idSearch = async () => {
    const newParams = new URLSearchParams();
    newParams.set('id', filters.id);
    toTop();
    await getFiltredBills(newParams, 0);
  };

  const applyFilters = async () => {
    toTop();
    await updateData(filters);
  };

  const resetBill = () => setBill({
    billSum: 0.00,
    files: []
  });

  const resetFilters = async () => {
    const newFilters = {
      ...filters,
      firm: undefined,
      org: undefined,
      type: undefined,
      exp: undefined,
      create: undefined,
      id: '',
      paid: false,
      confirmation: false,
      canceled: false,
      awaitingPayment: false
    };
    setFilters(newFilters);
    toTop();
    await updateData(newFilters);
  };

  const getMyBills = async (params, offset) => {
    const response = await api(`bill/my?offset=${offset}${params.size ? `&${params}` : ''}`);
    if (response.status === 200) {
      if (offset === 0) {
        setBills(response.data);
        if (response.data.length) {
          setEmpty(false);
        } else {
          setEmpty(true);
        }
      } else {
        setBills([
          ...bills,
          ...response.data
        ]);
        if ([
          ...bills,
          ...response.data
        ].length) {
          setEmpty(false);
        } else {
          setEmpty(true);
        }
      }
    }
  };

  const getData = async () => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });

    await getMyBills(newParams, 0);
  };

  const getFiltredBills = async (params, offset) => {
    toggleLoading();
    await getMyBills(params, offset);
    toggleLoading();
  };

  const setNewBill = (data) => setBill({
    ...bill,
    ...data
  });

  const toggleIsOpenModal = () => setIsOpenModal((prev) => !prev);

  const toggleModelCreate = () => {
    setModalMode('create');
    setIsOpenModal((prev) => !prev);
  };

  const toggleModelEdit = () => {
    setModalMode('edit');
    setIsOpenModal((prev) => !prev);
  };

  const fetchDataFromBill = async () => {
    const response = await api(`bill/data_from_create?author=${filters.author}`);
    if (response.status === 200) {
      setExpenses(response.data.expenses);
      setFirms(
        response.data.firms.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );

      setOrganizations(
        response.data.organization.sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        })
      );
      if (response.data.organization.length === 1) {
        setNewBill({organization: 0});
      }
    }
  };

  const openModalCreate = async () => {
    toggleModelCreate();
  };

  const openModalEdit = (data) => {
    setFiles(data.files);
    setBill({
      ...bill,
      ...{
        id: data.id,
        files: data.files,
        expenses: data.expensesId,
        firm: data.firmId,
        organization: data.expensesId,
        howToPay: data.howToPay,
        comment: data.billComments.length ? data.billComments[0].comment : '',
        billSum: data.sum
      }
    });
    toggleModelEdit();
  };

  const closeModal = () => {
    if (modalMode === 'edit') {
      bill.files.forEach((file) => {
        if (!files.includes(file)) {
          handleRemoveFile(file?.response?.file?.filename);
        }
      });
    }
    resetBill();
    toggleModelCreate();
  };

  const createBill = async () => {
    setIsCreateBill(true);
    const response = await api('bill/create', {
      method: 'POST',
      body: {
        ...bill,
        files: bill.files.map((file) => file?.response?.file?.filename),
        organization: organizations[bill.organization]
      }
    });
    setBills([
      {
        ...response.data,
        expenses: expenses.find((el) => el.id === bill.expenses),
        files: bill.files,
        firm: firms.find((el) => el.id === bill.firm),
        organization: organizations[bill.organization],
        howToPay: bill.howToPay,
        comment: bill.comment,
        sum: bill.billSum,
        currency: userSelf.currency
      },
      ...bills
    ]);
    resetBill();
    setIsCreateBill(false);
    toggleIsOpenModal();
  };

  const editBill = async () => {
    setIsCreateBill(true);
    files.forEach((file) => {
      if (!bill.files.includes(file)) {
        handleRemoveFile(file.name);
      }
    });
    await api('bill/edit', {
      method: 'PUT',
      body: {
        id: bill.id,
        howToPay: bill.howToPay,
        comment: bill.comment,
        files: bill.files.map((val) => val?.response?.file?.filename).filter((val) => !!val)
      }
    });
    resetBill();
    setIsCreateBill(false);
    toggleIsOpenModal();
    updateData(filters);
  };

  const beforeUpload = (file) => {
    const mimeType = [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ];
    const isIMG = mimeType.some((type) => file.type === type);
    if (!isIMG) {
      message.error(`${file.name} не поддерживаемый формат файла`, 5);
    }
    return isIMG || Upload.LIST_IGNORE;
  };

  const handleChange = (info) => {
    const newFileList = info.fileList.map((file) => {
      if (file.response) {
        // eslint-disable-next-line no-param-reassign
        file.url = `api/upload/${file.response.file.filename}`;
      }
      return file;
    });
    setNewBill({files: newFileList});
  };

  const handleRemoveFile = (name) => {
    api(`upload/${name}`, {
      method: 'DELETE',
      headers: {'Content-Type': 'application/json'}
    });
  };

  const setExpenseToBill = (expensesLocal) => {
    setNewBill({expenses: expensesLocal});
  };

  const setHowToPay = (e) => {
    setNewBill({howToPay: e.target.value});
  };

  const setBillSum = (e) => {
    setNewBill({billSum: e});
  };

  const setFirm = (firm) => {
    setNewBill({firm});
  };

  const setOrganization = (organization) => {
    setNewBill({organization});
  };

  const setComment = (e) => {
    setNewBill({comment: e.target.value});
  };

  const objectList = [
    {
      key: 'expenses',
      title: 'Статья расходов',
      component: (
        <Select
          style={{width: '100%'}}
          placeholder="Выберете статью расходов"
          value={bill.expenses}
          disabled={modalMode === 'edit'}
          onChange={setExpenseToBill}
          options={expenses.map((el) => ({
            value: el.id,
            label: `${el.number} ${el.name}`,
            key: el.id
          }))}
        />
      )
    },
    {
      key: 'howToPay',
      title: 'За что платим',
      component: <Input placeholder="Введите текст" onChange={setHowToPay} value={bill.howToPay}/>
    },
    {
      key: 'billFile',
      title: 'Копия счета',
      component: (
        <Upload
          action="api/upload"
          listType="picture"
          beforeUpload={beforeUpload}
          multiple
          onChange={handleChange}
          onRemove={modalMode === 'create' ? (e) => handleRemoveFile(e.response.file.filename) : undefined}
          fileList={bill.files}
        >
          <Button type="primary" icon={<UploadOutlined/>}>
            Добавить
          </Button>
        </Upload>
      )
    },
    {
      key: 'billSum',
      title: 'Сумма счета',
      component: (
        <InputNumber
          min={0}
          ref={inputRef}
          controls={false}
          placeholder="Введите сумму счета"
          step="0.01"
          style={{width: '100%'}}
          addonAfter={userSelf.currency}
          disabled={modalMode === 'edit'}
          onChange={setBillSum}
          onFocus={handleFocus}
          value={bill.billSum}
        />
      )
    },
    {
      key: 'firm',
      title: 'Фирма',
      component: (
        <Select
          style={{width: '100%'}}
          placeholder="Выберете фирму"
          disabled={modalMode === 'edit'}
          value={bill.firm}
          onChange={setFirm}
          options={firms.map((el) => ({
            value: el.id,
            label: `${el.name}`,
            key: el.id
          }))}
        />
      )
    },
    {
      key: 'organization',
      title: 'Филиал',
      component: (
        <Select
          style={{width: '100%'}}
          placeholder="Выберете филиал"
          disabled={modalMode === 'edit'}
          value={bill.organization}
          onChange={setOrganization}
          options={organizations.map((el, i) => ({
            value: i,
            label: el.label,
            key: i
          }))}
        />
      )
    },
    {
      key: 'comment',
      title: 'Комментарий',
      component: <Input placeholder="Введите текст" onChange={setComment} value={bill.comment}/>
    }
  ];

  const checkRequired = () => bill.files.length === 0 || !bill.expenses || !bill.firm || !Object.hasOwn(bill, 'organization') || !bill.howToPay || !bill.billSum;

  const handleUndo = async (id) => {
    const response = await api(`bill/undo/${id}`, {method: 'POST'});
    if (response?.data?.status) {
      const billIndex = bills.findIndex((value) => value.id === id);
      const confirmationIndex = bills[billIndex].billConfirmations.findLastIndex((item) => item.status === 'new' || item.status === 'cancel' || item.status === 'paid' || item.status === 'confirm');
      const newBill = JSON.parse(JSON.stringify(bills[billIndex]));
      newBill.status = 'onСonfirmation';
      newBill.paidAtDate = null;
      newBill.paidAtTime = null;
      if (confirmationIndex > 0 && newBill.billConfirmations[confirmationIndex].status === 'new') {
        newBill.billConfirmations[confirmationIndex].status = null;
        newBill.billConfirmations[confirmationIndex - 1].status = 'new';
        newBill.billConfirmations[confirmationIndex - 1].eventDate = null;
      } else {
        newBill.billConfirmations[confirmationIndex].status = 'new';
      }
      newBill.billConfirmations[confirmationIndex].eventDate = null;
      newBill.confirmList = [...newBill.billConfirmations];
      newBill.commentEnd = null;
      // eslint-disable-next-line array-callback-return, consistent-return
      newBill.files = newBill?.files.filter((file) => {
        if (file.typeFile !== 'paymentCard') {
          return file;
        }
      });
      newBill.billFiles = [...newBill.files];
      setBills([
        ...bills.slice(0, billIndex),
        newBill,
        ...bills.slice(billIndex + 1)
      ]);
    } else {
      message.error('Не удалось отменить последнее действие');
    }
  };

  // eslint-disable-next-line consistent-return
  const excelExportHandler = async () => {
    const newParams = new URLSearchParams();
    Object.keys(filters).forEach((key) => {
      if (filters[key] && filters[key] !== 0) {
        newParams.set(key, filters[key]);
      }
    });
    await api(`bill/my-excel?${newParams.size ? `&${newParams}` : ''}`, {}, true).then((data) => data.data.blob()).then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'My bills.xlsx';
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <Space style={{marginBottom: '7px'}} direction="horizontal">
        <Space>
          <Button onClick={excelExportHandler} type="primary">
            Экспорт в Excel
          </Button>
        </Space>
      </Space>
      <div className={style.Container}>
        <div className={style.Cards}>
          {bills.map((el) => (
            <Bill
              cancelLast
              undoHandler={() => handleUndo(el.id)}
              editHandler={openModalEdit}
              editable
              bill={el}
              key={el.id}
            />
          ))}
          {bills.length && bills.length > 9 && !(bills.length % 10) ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Button type="primary" onClick={() => updateData(filters, bills.length)}>
                Загрузить ещё
              </Button>
            </div>
          ) : null}
          {empty &&
            <Result className="no-bills" icon={<SearchOutlined/>} status="info" title="Согласований не найдено"/>}
        </div>
        <div className={style.Filters}>
          <Filters
            mode="my"
            applyFilters={applyFilters}
            filters={filters}
            firms={firms}
            orgs={organizations}
            expenses={expenses}
            setOrgs={setOrgs}
            idSearch={idSearch}
            openModal={openModalCreate}
            resetFilters={resetFilters}
            setFilterByTitle={setFilterByTitle}
          />
        </div>
        <Modal
          open={isOpenModal}
          width={550}
          title={
            modalMode === 'create' ? (
              <Typography className={style.ModalTitle}>Создание согласования</Typography>
            ) : (
              <Typography className={style.ModalTitle}>Редактирование согласования</Typography>
            )
          }
          okText={modalMode === 'create' ? 'Создать' : 'Подтвердить'}
          cancelText="Закрыть"
          onCancel={closeModal}
          onOk={modalMode === 'create' ? createBill : editBill}
          cancelButtonProps={{disabled: isCreateBill}}
          okButtonProps={{
            loading: isCreateBill,
            disabled: checkRequired()
          }}
          maskClosable={false}
          closable={false}
        >
          <Space direction="vertical" style={{width: '100%'}}>
            <>
              {objectList.map((obj) => (
                <div className={style.ModalItem}>
                  <Typography
                    className={obj.key !== 'comment' ? style.ModalLabelRequired : style.ModalLabel}
                  >
                    {obj.title}
                  </Typography>

                  <div className={style.ModalObject}>{obj.component}</div>
                </div>
              ))}
            </>
          </Space>
        </Modal>
      </div>
    </>
  );
}

export default MyBill;
